import { Vue } from "vue-class-component";
import { AccessTypes, AssetsInterface } from "@/types";
import { usersModule } from "@/store";
import { checkAccess } from "@/permission";

export default class HasAccessToAssetMixin extends Vue {
  protected hasAccessToAsset(asset: AssetsInterface | undefined): boolean {
    if (!asset) return false;

    if (process.env.VUE_APP_NODE_ENV === "development") {
      return true;
    }

    if (!usersModule.IsAuthenticated) {
      return checkAccess(
        AccessTypes.Guest,
        asset?.accessType ?? AccessTypes.Guest
      );
    }

    return checkAccess(
      usersModule.userData.access as number,
      asset?.accessType ?? AccessTypes.Guest
    );
  }

  protected get hasAccessToPinnedAssets(): boolean {
    return checkAccess(
      usersModule.userData.access as number,
      AccessTypes.Registered
    );
  }
}
